import React, { Component, Children, cloneElement } from 'react'
import styled from 'styled-components'
import { Section, Text, Span, Anchor, Container } from 'components'
import 'components/css/faqs.css'
import Footer from 'components/Footer'
import Header from 'components/Header'
import Seo from '../components/Seo'

const Answer = styled(Container)`
  max-height: 100vh;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  color: #737373;
  overflow: hidden;
  transition-duration: 0.3s;

  &.closed {
    max-height: 0;
    padding-top: 0;
  }
`

const QuestionContainer = styled(Container)`
  padding: 32px;
  cursor: pointer;
  &:hover {
    background-color: #f8f8f8;
  }
`

const Question = styled.div`
  position: relative;
  display: flex;

  & > .Question-Text {
    flex: auto;
  }
`

const Chevron = styled.div`
  position: relative;
  padding-left: 8px;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  border: 2px solid transparent;
  border-bottom-color: #eb2426;
  border-right-color: #eb2426;
  transition: transform 0.3s;

  &.open {
    transform: rotate(225deg);
  }
`

function QuestionTab(props) {
  const { toggleTab, title, children, selected } = props
  return (
    <QuestionContainer
      py="sp4"
      px="df:[sp2] md:[sp3]"
      onClick={toggleTab}
      borderBottom="1px solid #e7e7e7"
    >
      <Question>
        <Text mr="sp3" className="Question-Text">
          {title}
        </Text>
        <Chevron className={selected && 'open'} />
      </Question>

      <Answer
        color="dark"
        lineHeight="2"
        className={!selected && 'closed'}
        fontStyle="italic"
      >
        {children}
      </Answer>
    </QuestionContainer>
  )
}

class QuestionAccordion extends Component {
  state = {
    selectedIndex: -1,
  }

  toggle = index => () => {
    const { selectedIndex } = this.state
    if (selectedIndex === index) {
      this.setState({ selectedIndex: -1 })
    } else {
      this.setState({ selectedIndex: index })
    }
  }

  getChildProps = (index, props) => ({
    toggleTab: this.toggle(index),
    selected: index === this.state.selectedIndex,
    ...props,
  })

  getChildren = () => {
    const { children } = this.props
    return Children.map(children, (child, index) =>
      cloneElement(child, this.getChildProps(index, child.props))
    )
  }

  render() {
    const children = this.getChildren()
    return (
      <Section py="sp3" mx="df:[sp3] md:[sp5] lg:[sp6]">
        {children}
      </Section>
    )
  }
}

function QuestionsSection() {
  return (
    <div>
      <Seo
        title="Digipay PH | FAQs"
        description="Digipay is a digital payments and financial services platform with
a mobile wallet, an industry leading biller ecosystem,
and an interoperable backend."
        keywords="digipay.ph/QuestionsSection, Digipay FAQS, Frequently, Asked, Questions, Frequently Asked, Frequently Asked Questions"
      />
      <Header></Header>
      <div className="faqs">
        <h1 align="center">FAQs</h1>
        <p className="text-muted" align="center">
          Frequently Asked Questions
        </p>
        <QuestionAccordion>
          <QuestionTab title="Paano mag-download ng DIGIPAY application?">
            <Text>
              Pumunta po sa Google Playstore gamit ang inyong Android Smartphone
              at i-type ang&nbsp;
              <Anchor
                color="primary"
                target="_blank"
                rel="noreferrer noopener"
                href="https://play.google.com/store/apps/details?id=com.fsgcapital.digipay&hl=en"
              >
                Digipay Agent
              </Anchor>
            </Text>
          </QuestionTab>

          <QuestionTab title="Saan makukuha ang PIN na hinihingi sa pag-lologin sa DIGIPAY account?">
            <Text>
              Ang PIN ay parang password na sariling gawa at isineset ng may-ari
              ng account.
            </Text>

            <Text>
              Sa unang beses na pag-login sa iyong DIGIPAY account ay
              kinakailangan magbigay ng&nbsp; sariling PIN na dagdag sa
              seguridad ng iyong account.
            </Text>

            <Text>
              Tandaang maigi ang iyong PIN dahil kinakailangang itype ito sa
              tuwing bubuksan ang&nbsp; DIGIPAY app.
            </Text>

            <Text>
              Sa loob ng ilang segundong hindi paggamit ng app ay automatic
              itong nagsasara at&nbsp; kinakailangang itype muli ang PIN bago
              ito mabuksan.
            </Text>
          </QuestionTab>

          <QuestionTab title="Paano magre-send ng resibo o Transaction Confirmation Receipt gamit ang Digipay App?">
            <Text>
              Magpunta lamang sa SALES DATA at i-filter sa HISTORY kung aling
              transaksyon ang nais&nbsp; muling makuha ang confirmation receipt.
              Kung napili na ang transaksyon, pindutin lamang&nbsp; ang
              cellphone icon kung nais matanggap ang resibo sa pamamagitan ng
              SMS at i-type ang&nbsp; cellphone number na padadalhan nito.
              Piliin naman ang envelope icon kung nais itong&nbsp; matanggap sa
              pamamagitan ng e-mail at saka i-type ang e-mail address na
              padadalhan nito.
            </Text>
          </QuestionTab>

          <QuestionTab title="Ano ang pwede naming gawin sa tuwing mayroon kaming katanungan tungkol sa DIGIPAY?">
            <Text>
              Maaari ninyong makausap ang aming Support Team sa{' '}
              <Span fontWeight="bold">Chat Support</Span>&nbsp; feature na
              matatagpuan sa app. Maari ninyo silang i-chat sa lahat ng mga
              katanungan&nbsp; kaugnay sa DIGIPAY app.
            </Text>
          </QuestionTab>

          <QuestionTab title="Paano namin mamo-monitor ang mga transaksyon at ang galaw ng pera sa aming wallet?">
            <Text>
              Ang mga transaksyon at galaw ng pera sa inyong wallet ay nasa
              ilalim ng Sales Data.&nbsp; Dito makikita ang History,
              Distribution, Statistics at Wallet Ledger.
            </Text>

            <Text>
              <Span fontWeight="bold">History</Span> - Dito makikita ang lahat
              ng detalye tungkol sa&nbsp; lahat ng transaksyon mula sa una
              hanggang sa pinaka bagong transaksyon.
            </Text>

            <Text>
              <Span fontWeight="bold">Distribution</Span> - Ipinapakita nito ang
              pagkakahati ng lahat&nbsp; ng gastos ng inyong wallet sa isang
              araw. Dito makikita kung aling transaksyon ang&nbsp; pinakamadalas
              maganap.
            </Text>

            <Text>
              <Span fontWeight="bold">Statistics</Span> - Ito ang nagtatala ng
              galaw ng pera at dalas&nbsp; ng transaksyon sa bawat buwan at
              araw.
            </Text>

            <Text>
              <Span fontWeight="bold">Wallet Ledger</Span> - Ito ang talaan na
              nagpapakita ng&nbsp; paglabas at pagpasok ng pera sa inyong wallet
              sa isang araw.
            </Text>
          </QuestionTab>

          <QuestionTab title="Paano makapagtransact ng maayos at mabilis?">
            Siguraduhin lamang na palaging updated ang ginagamit na DIGIPAY APP
            at siguraduhin din&nbsp; na mayroong internet connection.
          </QuestionTab>

          <QuestionTab title="Mayroon bang resibo na ibinibigay si Digipay?">
            <Text>
              Nag i-issue po tayo ng Transaction Confirmation Receipt. Mayroon
              pong 3 klase ng resibo&nbsp; na pwede ibigay sa customer. Maari
              pong SMS Receipt, Printed receipt at E-Mail.
            </Text>

            <Text>
              Sa inyong web browser pumunta lamang sa website ng DIGIPAY
              na&nbsp;
              <Anchor
                href="https://www.digipay.ph"
                target="_blank"
                color="primary"
                rel="noreferrer noopener"
              >
                https://www.digipay.ph
              </Anchor>
              .&nbsp; Mag login at i-click ang Sales Data. May mga icons po doon
              na pwedeng pagpipilian,&nbsp; maari ninyo itong i-print, isend sa
              email o isend sa mobile number ng customer.
            </Text>

            <Text>
              Ang bawat SMS receipt ay nagbabawas ng Piso (Php 1.00) sa inyong
              Digipay wallet&nbsp; per transaction.
            </Text>
          </QuestionTab>

          <QuestionTab title="Paano at saan makikita ang mga ginawang transactions?">
            <Text>
              Maaring makita sa Sales Data ang lahat ng mga successful
              transactions na ginawa.&nbsp; Pwede din po natin i-filter per
              transaction type at kung anong date lamang ang gusto ninyong
              makita.
            </Text>
          </QuestionTab>

          <QuestionTab title="Paano mabubuksan ang account kung nakalimutan ang password?">
            Maari kayong tumawag sa aming DIGIPAY hotline{' '}
            <Span fontWeight="bold">09270539396</Span>&nbsp; at{' '}
            <Span fontWeight="bold">(02) 7855246</Span> upang i-reset ang inyong
            password.&nbsp; Kayo po ay bibigyan ng temporary password at
            kailangan nyo pong i-login yung account&nbsp; ninyo para mapalitan
            ninyo agad ng sarili ninyong password.
          </QuestionTab>

          <QuestionTab title="Saan makikita ang mga nabawas at nadagdag na halaga sa aking Digipay wallet?">
            Maari pong pumunta sa Sales Data at piliin ang Wallet Ledger, dito
            makikita ang lahat&nbsp; ng pumasok at lumabas na pera sa inyong
            wallet. Tandaan po na ang makikita lang natin&nbsp; na impormasyon
            ay ang mga transaction sa loob ng isang araw.
          </QuestionTab>

          <QuestionTab title="Paano malalaman kung magkano ang income namin sa bawat transaction?">
            <Text>
              Maari pong pumunta sa Sales Data at piliin naman ang Revenue
              Report, dito makikita&nbsp; kung magkano ang kinita ninyo sa bawat
              transaction. Tandaan po na ang makikita lang&nbsp; natin na
              impormasyon ay ang mga transaction sa loob ng isang araw.
            </Text>

            <Text>
              Maari nyo rin po i-check sa Rate sheet na ibinigay po sainyo para
              malaman ninyo kung&nbsp; magkano ang kikitain nyo sa bawat
              transaction ng eload, billspay or mobile money.
            </Text>
          </QuestionTab>

          <QuestionTab title="Ano ang convenience fee?">
            Ito ay ang dagdag na kita na maaari mong ipatong sa bawat
            transaction. Ngunit may ilang&nbsp; billers na may maximum allowed
            service fee at kailangang sundin ito.
          </QuestionTab>

          <QuestionTab title="Ano ang System Charge?">
            May mga billers na may System Charge pa sa ibabaw ng halaga ng bill
            na babayaran ng&nbsp; customer. Sa ganitong pagkakataon, papatungan
            mo pa ito ng sarili mong convenience fee&nbsp; at iyon ang iyong
            kita.
          </QuestionTab>

          <QuestionTab title="Ilang araw bago ma-post ang binayad para sa Bills Payment?">
            Ang posting po ng bawat transaction ay 2 to 3 working days. Kapag
            meron pong customer&nbsp; na nagreklamo na hindi pa naipo-post ang
            payment, pwede po ninyo ibigay ang&nbsp; Reference Number na
            nagenerate po sa resibo at maari po nila ito ipakita as proof of
            payment.
          </QuestionTab>

          <QuestionTab title="Paano maiiwasan ang Double Transaction?">
            Siguraduhin na updated ang DIGIPAY App sa inyong device. Kung kayo
            ay nagta-transact at&nbsp; hindi kaagad ito pumasok, i-refresh o
            i-restart muna ang app at tignan kung ang inyong&nbsp; wallet ay
            nabawasan o hindi. Maari din i-check ang sales data kung mayroong
            pumasok na&nbsp; transaction. Kung wala naman po ay maaari nyo ng
            ulitin ang transaction.
          </QuestionTab>

          <QuestionTab title="Bakit ayaw ibigay ang pera ng ibang Smart Money outlets?">
            Mayroon po tayong dalawang klase ng Smart Money Account numbers. Ang
            mga nagsisimula sa&nbsp; 5577 ay legitimate or authorized na Smart
            Padala Centers habang ang mga nagsisimula sa&nbsp; 5299 ay mga
            personal na Smart Money account lamang. Siguraduhin lang po na dun
            lamang po&nbsp; tayo magpadala sa lehitimong Smart Padala Stores
            para maiwasan po ang delays at issues sa&nbsp; pag Money Out.
          </QuestionTab>

          <QuestionTab title="Kailan pwede magpadala sa Smart Money Account na nagsisimula sa 5299?">
            Maaari lamang po natin padalhan ang account number na nagsisimula sa
            5299 kapag Money In&nbsp; lang ang ginagawang transaction. Ang ibig
            sabihin po ay personal account po ito ni&nbsp; customer at nais nya
            po lamang lagyan ng load ang kanyang personal na Smart Money
            account.
          </QuestionTab>

          <QuestionTab title="May limit po ba ang magpadala sa Smart Padala?">
            Mayroon pong daily limit sa pag Money In at Money Out ang mga Smart
            Money Account&nbsp; numbers na nagsisimula sa 5299. Siguraduhin po
            lamang natin na sa mga lehitimong&nbsp; Smart Padala outlets lang
            tayo magpadala na nagsisimula sa numerong (5577)
          </QuestionTab>

          <QuestionTab title="Which types of transactions can I do using Digipay?">
            <Text>Purchase airtime and digital products, pay bills</Text>
            <Text>Transfer funds to mobile money and bank accounts</Text>
            <Text>Record your transaction</Text>
          </QuestionTab>
        </QuestionAccordion>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default QuestionsSection
